import { useHandleApi } from 'hooks';
import { VideoMeta } from 'types/components/creatives';

/* TODO(Jenky): unit tests! */
const useCreativeAdsManager = () => {
  const { handleApi, loading } = useHandleApi();

  const getVideoMetaFromAssets = async (creativeAssets: number[]) => {
    if (!creativeAssets.length) return {};

    const response = await handleApi({
      method: 'GET',
      endpoint: `/fb/ads/video?creativeAssetId=${creativeAssets.join(',')}`,
    });

    return response.data.metadata as { [key: string]: VideoMeta };
  };

  return {
    getVideoMetaFromAssets,
    loading,
  };
};

export default useCreativeAdsManager;
