import styled from '@emotion/styled';
import { VideoMeta } from 'types/components/creatives';

type VideoPlayerProps = {
  meta: VideoMeta | null;
};

const Video = styled.video`
  width: 100%;
  height: 100%;
`;

const VideoPlayerl = (props: VideoPlayerProps) => {
  const { meta } = props;

  const { source } = meta ?? {};

  return (
    <Video
      controls
      src={source}
      controlsList="nodownload noremoteplayback nofullscreen"
      crossOrigin="anonymous"
    >
      <source src={source} />
      <track kind="captions" />
    </Video>
  );
};

export default VideoPlayerl;
