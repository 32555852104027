import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';

const NoTableDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`;

const CenteredText = styled(Text)`
  line-height: 376px;
  text-align: center;
`;

const NoTableData = () => (
  <NoTableDataContainer>
    <CenteredText variant="h4" color="neutral40">
      No Data Available
    </CenteredText>
  </NoTableDataContainer>
);

export default NoTableData;
