import styled from '@emotion/styled';
import { InsightsHeader } from '../SharedLayout';
import TopAdsFilters from './Layout/TopAdsFilters';
import { TopAdsViewProps } from './types';
import TopAdsTabs from './Layout/TopAdsTabs';
import TopAdsTiles from './Layout/TopAdsTiles';

/* this needs to go into a shared layout component */
const TopAdsInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 32px 0;
  background-color: white;
  height: calc(100vh - 69px);
`;

const TopAdsStickyTop = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
`;

const TopAdsView = (props: TopAdsViewProps) => {
  const { brand } = props;
  return (
    <>
      <TopAdsStickyTop>
        <InsightsHeader brandName={brand.name} title="Explore Top Ads" helpLink="" />
      </TopAdsStickyTop>
      <TopAdsInnerContainer>
        <TopAdsFilters {...props} />
        <TopAdsTabs {...props} />
        <TopAdsTiles {...props} />
      </TopAdsInnerContainer>
    </>
  );
};

export default TopAdsView;
