import { AnglerIntegrationModalProps } from 'types/components/integrations';
import AnglerIntegrationModalView from './AnglerIntegrationModal.View';
import useAngler from 'hooks/useAngler';

const AnglerIntegrationModal = (props: Pick<AnglerIntegrationModalProps, 'open' | 'onClose'>) => {
  const { open, onClose } = props;
  const { onConnectAnglerClick } = useAngler();

  return (
    <AnglerIntegrationModalView
      onConnectAnglerClick={onConnectAnglerClick}
      open={open}
      onClose={onClose}
    />
  );
};

export default AnglerIntegrationModal;
