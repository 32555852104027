import { TopAdsFiltersProps } from '../../types';
import { FlavorCategory } from 'types/components/audiences';
import { useEffect, useState } from 'react';
import { useProximaSDK } from 'hooks';
import { colors, SelectInput, SelectOption } from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';
import LoadingSpinner from 'ui/LoadingSpinner';

const IntervalSelectInputWrapper = styled.div`
  div {
    border-radius: 4px;

    > fieldset {
      border: 2px solid ${colors.neutral20} !important;
    }
  }
`;

const TopAdsFiltersCategories = (props: TopAdsFiltersProps) => {
  const { onFilterChange, filters } = props;
  const audiencesApi = useProximaSDK('AudiencesApi');
  const [categories, setCategories] = useState<FlavorCategory[]>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await audiencesApi.getFlavorCategories();

      setCategories(response.data as FlavorCategory[]);
    };

    fetchCategories();
  }, []);

  const onCategorySelect = (value: string) => {
    onFilterChange('category', value);
  };

  const options: SelectOption[] = [
    {
      label: 'All Categories',
      value: 'all',
    },
    ...categories.map(
      (cat) =>
        ({
          label: cat.name,
          value: cat.id,
        } as SelectOption)
    ),
  ];

  if (audiencesApi.loading) return <LoadingSpinner />;

  if (filters.category === null) return <></>;

  return (
    <IntervalSelectInputWrapper>
      <SelectInput
        key={filters.category}
        onChange={onCategorySelect}
        options={options}
        value={filters.category as string}
      />
    </IntervalSelectInputWrapper>
  );
};

export default TopAdsFiltersCategories;
