import Layout from '../Terms.Layout';

const PrivacyView = () => (
  <Layout>
    <h2>Innovation Brands Corp.</h2>
    <h2>Privacy Policy</h2>

    <p>Last Updated: September 30, 2024</p>

    <p>
      This Privacy Policy describes the policies and procedures of Innovation Brands Corp. d/b/a
      Proxima.ai and its subsidiaries and affiliates (&ldquo;Innovation Brands&rdquo;,
      &ldquo;we&rdquo;, &ldquo;our&rdquo;, or &ldquo;us&rdquo;) regarding the collection, use and
      disclosure of your information on www.proxima.ai and www.dojomojo.com (the
      &ldquo;Sites&rdquo;) and the services, features or content we offer (collectively with the
      Sites, the &ldquo;Services&rdquo;). We receive information about you from various sources,
      including: (i) if you register for the Services through an Innovation Brands account; (ii)
      your use of the Services generally; and (iii) from Third-Party Services. When you use the
      Services, you are consenting to the collection, transfer, storage, disclosure, and other uses
      of your information as described in this Privacy Policy.
    </p>

    <p>
      Our Services are designed for business customers (&ldquo;Customers&rdquo;) and their
      representatives. Customers can use the Services to market to consumers who are on
      Customers&rsquo; email marketing list (these consumers are called &ldquo;Subscribers&rdquo;)
      and to acquire new Subscribers by partnering with other Customers to run a campaign or
      sweepstakes that requires participants in the campaign or sweepstakes to opt-in to the
      Customers&rsquo; marketing.
    </p>

    <h3>What Does This Privacy Policy Cover?</h3>

    <p>
      This Privacy Policy covers the treatment of personally identifiable information
      (&ldquo;Personal Information&rdquo;) gathered when Customers are using or accessing the
      Services. This Privacy Policy does not apply to Personal Information that we process on behalf
      of Customers while providing the Services to them. This Privacy Policy also does not apply to
      the practices of third parties that we do not own or control, including but not limited to any
      third-party websites, services, and applications (&ldquo;Third-Party Services&rdquo;) that you
      choose to access through the Services. While we attempt to facilitate access only to those
      Third-Party Services that share our respect for your privacy, we cannot take responsibility
      for the content or privacy policies of those Third-Party Services. We encourage you to
      carefully review the privacy policies of any Third-Party Services you access.
    </p>

    <h3>What Personal Information Do We Collect?</h3>

    <p>
      The information we gather enables us to personalize, improve and continue to operate the
      Services. In connection with certain aspects of the Services, we may request, collect and/or
      display some of your Personal Information.
    </p>

    <h4>Innovation Brands Customers</h4>
    <p>Account Information:</p>
    <p>
      When you create an account, you will provide information that could be Personal Information,
      such as your name, username, password, email address, phone number, gender, occupation,
      location, birthdate, and other demographic information. You acknowledge that this information
      may be personal to you, and by creating an account on the Services and providing Personal
      Information to us, you allow others, including us, to identify you. If you use a third-party
      platform, like Google or LinkedIn, to create an account, we may collect Personal Information
      from that third-party platform. You can control the information the third-party platform
      shares with us in your account settings with that third-party platform. We may use your
      contact information to send you information about our Services, but only rarely when we feel
      such information is important. We reserve the right to contact you when we believe it is
      necessary, such as for account recovery purposes.
    </p>

    <p>User Content:</p>
    <p>
      Some features of the Services allow you to provide content to the Services, such as messages
      and written comments. All content submitted by you to the Services may be retained by us
      indefinitely, even after you terminate your account, or as otherwise permitted under
      applicable laws. We may continue to disclose such content to third parties in a manner that
      does not reveal Personal Information, as described in this Privacy Policy.
    </p>

    <h4>All Innovation Brands Users</h4>
    <p>
      If you interact with the Services, such as by viewing the Sites or opening an email sent
      through Innovation Brands, we will collect certain information from you.
    </p>

    <p>Information Collected Automatically:</p>

    <p>
      We automatically receive and record information from your web browser when you interact with
      the Services, including your IP address, time and date of such interaction, the browsers used,
      and the actions you&rsquo;ve taken within the Services. This information is used for fighting
      spam/malware and also to track your interaction with the Services (e.g., what links you have
      clicked on). The Services also automatically collect usage information, such as the number and
      frequency of visitors to the Site, links you may click on through the Sites or emails we send,
      conversions of Subscribers in Innovation Brands campaigns or sweepstakes (where applicable),
      demographics of Subscribers, and geographic location of visitors to the Site. We may use this
      data in aggregate form, that is, as a statistical measure, but not in a manner that would
      identify you.
    </p>
    <p>Cookies and Web Beacons:</p>
    <p>
      This Privacy Policy covers our use of cookies and web beacons only and does not cover the use
      of cookies by third parties. We do not control when or how third parties place cookies on your
      computer. For example, if you click a link that leads to a third-party website, that website
      may set cookies on your computer.
    </p>

    <p>
      <u>Cookies:</u>
    </p>
    <p>
      Cookies are pieces of text that may be provided to your computer through your web browser when
      you access a website. Your browser stores cookies in a manner associated with each website you
      visit. We use cookies to enable our servers to recognize your web browser and tell us how and
      when you visit the Sites and otherwise use the Services through the Internet. We may associate
      this information with your Innovation Brands account if you have one.
    </p>

    <p>
      <u>Web Beacons:</u>
    </p>
    <p>
      We, and third parties with whom we partner, such as Meta, may also deliver a file known as a
      web beacon, pixel or pixel tag (&ldquo;web beacons&rdquo;) to you through our Services. Web
      beacons are small, invisible graphic images that may be used on Service interfaces or in
      emails relating to the Services to collect certain information and monitor user activity on
      the interfaces, such as to count visits, understand usage effectiveness and to determine if an
      email has been opened and acted upon.
    </p>
    <p>Aggregate Information:</p>

    <p>
      We collect statistical information about how both unregistered and registered users,
      collectively, use the Services (&ldquo;Aggregate Information&rdquo;). Some of this information
      is derived from Personal Information, but was de-identified.
    </p>
    <p>Publicly Available Information:</p>
    <p>
      We may collect information that is publicly available, such as in public records made
      available by state and local governments, and combine this information with Personal
      Information you provide through the Services.
    </p>

    <h4>How Do We Use Your Personal Information?</h4>
    <p>We may use Personal Information to:</p>

    <ul>
      <li>
        <p>
          Communicate about our Services, and respond to requests, inquiries, comments, and
          suggestions
        </p>
      </li>

      <li>
        <p>
          Analyze and enhance communications and strategies (including by identifying when emails
          sent to you have been received and read) for Innovation Brands and our Customers
        </p>
      </li>

      <li>
        <p>
          {' '}
          Analyze results from marketing campaigns and sweepstakes (where applicable) for our
          Customers
        </p>
      </li>

      <li>
        <p>
          Operate, evaluate and improve our Services (including to develop new products and
          services)
        </p>
      </li>

      <li>
        <p> Establish and maintain your Customer profile in our Services</p>
      </li>

      <li>
        <p> Tailor the content we display in our Services</p>
      </li>

      <li>
        <p> Administer surveys and other market research</p>
      </li>

      <li>
        <p> Invoice and collect payment for our Services</p>
      </li>

      <li>
        <p>
          Comply with legal requirements, judicial process and our company policies (including to
          verify your identity in connection with data access or update requests)
        </p>
      </li>

      <li>
        <p>
          Protect against, identify, investigate and respond to fraud, illegal activity (such as
          incidents of hacking or misuse of our websites and mobile applications), and claims and
          other liabilities, including by enforcing the Terms of Use that govern the Services
        </p>
      </li>
    </ul>

    <h3> How, and With Whom, Is Your Personal Information Shared?</h3>
    <p>
      The Services are designed to help you share information with others. As a result, some of the
      information generated through the Services is shared publicly or with third parties.
    </p>

    <h4>Innovation Brands Customers </h4>
    <p>
      If you have an Innovation Brands account, your profile information including your username and
      other information you enter may be displayed to other Customers to facilitate interaction
      within the Services. We will not directly reveal your email address to other Customers. When
      you partner with other Innovation Brands Customers to collaborate on a campaign or sweepstakes
      (where applicable), you agree to share certain information, including your contact information
      and information about your Subscribers who opt in to receive marketing emails from the
      partners.
    </p>
    <h4>All Innovation Brands Users</h4>

    <p>
      Some of your activity on and through the Services is public by default. This may include, but
      is not limited to, content you have posted publicly on the Sites or otherwise through the
      Services. If you are an Innovation Brands Customer, some of this information may be associated
      with your account.
    </p>

    <p>
      Please also remember that if you choose to provide Personal Information using certain public
      features of the Services, then that information is governed by the privacy settings of those
      particular features and may be publicly available. Individuals reading such information may
      use or disclose it to other individuals or entities without our control and without your
      knowledge, and search engines may index that information. We therefore urge you to think
      carefully about including any information you consider private in content that you create or
      information that you submit through the Services.
    </p>

    <p>Affiliates:</p>
    <p>
      We may share your Personal Information with our corporate parent, subsidiaries, and
      affiliates, for purposes consistent with this Privacy Policy.
    </p>
    <p>Service Providers:</p>
    <p>
      We may share your Personal Information with third-party companies and individuals that provide
      services on our behalf or help us operate the Services (such as payment processing, hosting,
      analytics, email delivery, Customer service, marketing, and database management services).
      These third parties may use your Personal Information only as directed or authorized by us and
      in a manner consistent with this Privacy Policy and are prohibited from using or disclosing
      your information for any other purpose.
    </p>
    <p>Aggregate Information:</p>
    <p>
      We share Aggregate Information with our partners, service providers and other persons with
      whom we conduct business. We share this type of statistical data so that our partners can
      understand how and how often people use our Services and their services or websites, which
      facilitates improving both their services and how our Services interface with them. In
      addition, these third parties may share with us aggregated or otherwise non-personally
      identifiable information about you that they have independently developed or acquired.
    </p>

    <p>Business Transfers:</p>
    <p>
      We may disclose your Personal Information in connection with an actual or prospective business
      transaction (or potential business deal) such as a merger, consolidation, acquisition,
      reorganization, sale of assets, or in the event of bankruptcy. For example, we may need to
      share certain Personal Information with prospective counterparties and their advisers.
    </p>
    <p>Professional Advisors:</p>
    <p>
      We may disclose your Personal Information with advisors, such as lawyers, auditors, bankers,
      and insurers, where necessary in the course of the professional services that they render to
      us.
    </p>
    <p>Information Disclosed for Our Protection and the Protection of Others:</p>
    <p>
      We also reserve the right to access, read, preserve, disclose, and otherwise process any
      information as it reasonably believes is necessary to (i) satisfy any applicable law,
      regulation, legal process or governmental request, (ii) enforce this Privacy Policy and our
      Terms of Use, including investigation of potential violations hereof, (iii) detect, prevent,
      or otherwise address fraud, security or technical issues, (iv) respond to user support
      requests, or (v) protect our rights, property or safety, our users and the public. This
      includes exchanging information with other companies and organizations for fraud protection
      and spam/malware prevention.
    </p>
    <h4>Is Your Personal Information Secure?</h4>
    <p>
      We transmit and store your information, including your IP address information, using
      techniques designed to protect the security and confidentiality of your information. However,
      we cannot guarantee or warrant that such techniques will prevent unauthorized access to your
      information, Personal Information or otherwise.
    </p>
    <h3>What Choices Do You Have Regarding Your Personal Information?</h3>
    <h4>Innovation Brands Customers</h4>
    <p>Account Settings:</p>
    <p>
      You can access and update information associated with your account by logging into the
      Services.
    </p>
    <p>Delete Your Account:</p>
    <p>
      Should you ever decide to delete your account, you may do so by contacting us. Please note
      that we will need to verify that you have the authority to delete the account. If you
      terminate your account, any association between your account and information we store will no
      longer be accessible through your account. However, given the nature of sharing on the
      Services, any public activity on your account prior to deletion will remain stored on our
      servers and will remain accessible to the public subject to the Terms of Use.
    </p>

    <h4>All Innovation Brands Users</h4>
    <p>Choose not to Share Information:</p>
    <p>
      You can always choose not to disclose certain information to us. However, where we need
      information to provide the Services, you may not be able to take advantage of some of our
      features.
    </p>
    <p>Opt-out of marketing communications:</p>
    <p>
      You may opt-out of marketing-related emails by following the opt-out or unsubscribe
      instructions at the bottom of the email. You may also opt-out of marketing-related emails by
      contacting us as detailed below. Please note that if you choose to opt-out of
      marketing-related emails, you may continue to receive service-related and other non-marketing
      emails.
    </p>
    <p>Cookies:</p>
    <p>
      Most browsers have an option for disabling certain cookies. We strongly recommend that you
      leave cookies active because cookies allow you to take advantage of all of the features we
      offer through the Services.
    </p>
    <p>
      We currently do not participate in any <strong>&ldquo;Do Not Track&rdquo;</strong> frameworks
      that would allow us to respond to signals or other mechanisms from you regarding the
      collection of your information. To find out more about &ldquo;Do Not Track,&rdquo; please
      visit{' '}
      <a href="https://www.allaboutdnt.com" rel="noreferrer noopener" target="_blank">
        www.allaboutdnt.com
      </a>
      .
    </p>
    <h3>What if Children Access the Services?</h3>
    <p>
      We do not knowingly collect or solicit Personal Information from children under 13. If we
      learn we have collected Personal Information from a child under 13, we will delete that
      information as quickly as possible. If you believe that a child under 13 may have provided us
      Personal Information, please contact us at{' '}
      <a href="mailto:support@proxima.ai">support@proxima.ai</a>.
    </p>
    <h3>What If You Use the Services Outside of the United States?</h3>
    <p>
      The Services are operated from the United States. Any information we obtain about you in
      connection with your use of the Service may be processed and stored in, and subject to the
      laws of, the United States or other countries. Privacy laws in the locations where we process
      and store your Personal Information may not be as protective as the privacy laws in your home
      country.
    </p>
    <h3>What Happens When There Are Changes to this Privacy Policy?</h3>
    <p>
      We may amend this Privacy Policy from time to time. If we make material changes in the way we
      collect or use information, we will notify you by posting an announcement on the Services or
      sending you an email. If you use the Services after we post changes to our Privacy Policy, you
      are bound by those changes.
    </p>
    <h3>What If You Have Questions or Concerns?</h3>
    <p>
      If you have any questions or concerns regarding Innovation Brands&rsquo; privacy practices,
      please send us a detailed message:
    </p>
    <p>
      By email: <a href="mailto:support@proxima.ai">support@proxima.ai</a>
    </p>
    <p>By post:</p>
    <p style={{ paddingLeft: '2em' }}>
      Innovative Brands Corp. <br />
      100 Crosby St, # 506 <br />
      New York, NY 10012
    </p>
  </Layout>
);

export default PrivacyView;
