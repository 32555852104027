import styled from '@emotion/styled';
import { ArrowDropDown, ArrowDropUp, Text, colors } from '@innovationdepartment/proxima-ui';
import { InsightsMetric } from 'types/insights';

const PercentChangeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  gap: 8px;
`;

const BrandChangeContainer = styled.div`
  background-color: ${colors.neutral5};
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 4px;
`;

const ChangeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 4px;
`;

const RedDropUpContainer = styled.div`
  > svg > path {
    fill: #e14436;
  }
`;

const GreenDropDownContainer = styled.div`
  > svg > path {
    fill: #00ad23;
  }
`;

type PercentChangeContainerProps = {
  metric: InsightsMetric;
  brandChange: string;
  flavorCategoryChange: string;
  brandArrowDirection: string;
  flavorArrowDirection: string;
};

const PercentChange = (props: PercentChangeContainerProps) => {
  const { metric, brandChange, flavorCategoryChange, brandArrowDirection, flavorArrowDirection } =
    props;

  const cpaBrandArrow =
    brandArrowDirection === 'up' ? (
      <RedDropUpContainer>
        <ArrowDropUp />
      </RedDropUpContainer>
    ) : (
      <GreenDropDownContainer>
        <ArrowDropDown />
      </GreenDropDownContainer>
    );
  const cpaFlavorArrow =
    flavorArrowDirection === 'up' ? (
      <RedDropUpContainer>
        <ArrowDropUp />
      </RedDropUpContainer>
    ) : (
      <GreenDropDownContainer>
        <ArrowDropDown />
      </GreenDropDownContainer>
    );

  const roasBrandArrow = brandArrowDirection === 'up' ? <ArrowDropUp /> : <ArrowDropDown />;
  const roasFlavorArrow = flavorArrowDirection === 'up' ? <ArrowDropUp /> : <ArrowDropDown />;

  const brandArrowComponent = metric === InsightsMetric.Cpa ? cpaBrandArrow : roasBrandArrow;
  const flavorArrowComponent = metric === InsightsMetric.Cpa ? cpaFlavorArrow : roasFlavorArrow;
  return (
    <PercentChangeContainer>
      <ChangeContainer>
        {flavorCategoryChange === '-' ? <></> : flavorArrowComponent}
        <Text variant="table1">{flavorCategoryChange}</Text>
      </ChangeContainer>
      {brandChange === '-' ? (
        <ChangeContainer>
          <Text variant="body2Medium">{brandChange}</Text>
        </ChangeContainer>
      ) : (
        <BrandChangeContainer>
          {brandArrowComponent}
          <Text variant="body2Medium">{brandChange}</Text>
        </BrandChangeContainer>
      )}
    </PercentChangeContainer>
  );
};

export default PercentChange;
