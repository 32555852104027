import { PropsOf } from '@emotion/react';
import styled from '@emotion/styled';
import {
  DatePicker,
  MetaNoBorder,
  SelectInput,
  Text,
  Union,
  colors,
} from '@innovationdepartment/proxima-ui';
import { IntervalOption, InsightsInterval } from 'types/insights';

const SubheaderContainer = styled.div`
  background-color: ${colors.neutral5};
  border-bottom: 1px solid ${colors.neutral20};
  display: flex;
  flex-direction: row;
  height: 60px;
  padding: 0px 32px;
  justify-content: space-between;
  align-items: center;
`;

const SubheaderTabs = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

const SocialChannelTab = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
  padding: 8px;

  > svg {
    height: 20px;
    width: 20px;
  }
`;

const SubheaderDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

const IconLabelContainer = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
`;

const IntervalSelectInputWrapper = styled.div`
  div {
    border-radius: 4px;

    > fieldset {
      border: 2px solid ${colors.neutral20} !important;
    }
  }
`;

const CapitalText = styled(Text)`
  text-transform: capitalize;
`;

type InsightsHeaderProps = {
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  interval: string;
  intervalOptions: IntervalOption[];
  onDateRangeSelect: PropsOf<typeof DatePicker>['onDateRangeSelect'];
  onIntervalChanged: (interval: InsightsInterval) => void;
};

const TrendsSubheader = (props: InsightsHeaderProps) => {
  const { dateRange, interval, intervalOptions, onDateRangeSelect, onIntervalChanged } = props;

  return (
    <SubheaderContainer>
      <SubheaderTabs>
        <SocialChannelTab>
          <MetaNoBorder />
          <Text color="neutral90" variant="body1">
            Meta
          </Text>
        </SocialChannelTab>
      </SubheaderTabs>
      <SubheaderDetails>
        <DatePicker
          dateRange={{
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          }}
          onDateRangeSelect={onDateRangeSelect}
          withRange
          showSelectWithDateRange={false}
          disableFutureDates
        />
        <IntervalSelectInputWrapper>
          <SelectInput
            options={intervalOptions}
            size="small"
            value={interval}
            renderValue={() => (
              <IconLabelContainer>
                <Union />
                <CapitalText variant="body2Semibold" color="neutral90">
                  By {interval}
                </CapitalText>
              </IconLabelContainer>
            )}
            onChange={(newInterval) => onIntervalChanged(newInterval as InsightsInterval)}
          />
        </IntervalSelectInputWrapper>
      </SubheaderDetails>
    </SubheaderContainer>
  );
};

export default TrendsSubheader;
