import styled from '@emotion/styled';
import { Text, TextVariantType, colors } from '@innovationdepartment/proxima-ui';
import React from 'react';

type TagProps = {
  label: string | React.ReactNode;
  disabled?: boolean;
  variant?: TextVariantType;
};

const TagWrapper = styled.div<Partial<TagProps>>`
  display: inline-flex;
  align-items: center;
  padding: 6px 8px;
  border-radius: 14px;
  background-color: ${({ disabled }) => (disabled ? colors.neutral5 : colors.neutral10)};
`;

// TODO(Jenky): Might move this to UI library
const Tag = ({ label, disabled = false, variant = 'tag2Medium' }: TagProps) => (
  <TagWrapper disabled={disabled}>
    {typeof label === 'string' ? (
      <Text variant={variant} color={disabled ? 'neutral30' : 'neutral80'}>
        {label}
      </Text>
    ) : (
      <>{label}</>
    )}
  </TagWrapper>
);

export default Tag;
