export enum IntegrationType {
  Facebook = 'facebook',
  Shopify = 'shopify',
  TikTok = 'tiktok',
  Klaviyo = 'klaviyo',
  Angler = 'angler',
  ManualImport = 'manual_import',
}

export enum BrandIntegrationStatus {
  Connected = 'connected',
  Disconnected = 'disconnected',
  Incomplete = 'incomplete',
  TermsRequired = 'terms-required',
  NeverConnected = 'never-connected',
}
