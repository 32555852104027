import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useToaster } from '@innovationdepartment/proxima-ui';
import { AxiosError } from 'axios';
import { Outlet, Navigate, useParams } from 'react-router-dom';
import { useBrandStore, useUserStore } from 'stores';
import { useIntegrations, useProximaSDK, useShowSpinner } from 'hooks';
import { Brand } from 'types/stores/brand-store';
import * as analytics from 'lib/analytics';
import * as datadog from 'lib/datadog';

const BrandLoader = () => {
  const { brandId } = useParams();

  const { logout } = useAuth0();
  const { brand: storedBrand } = useBrandStore();
  const brandApi = useProximaSDK('BrandsApi', { brandId, ignoreCache: !storedBrand?.brandId });
  const { user } = useUserStore();
  const { updateBrandStore } = useBrandStore();
  const { showToaster } = useToaster();
  const { loading: integrationLoading, initializeIntegrations } = useIntegrations();

  const [loading, setLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);
  const [integrationsReady, setIntegrationsReady] = useState(false);

  const showError = (msg?: string) => {
    const errorMsg = msg ?? 'Something went wrong, please try again';
    showToaster({ message: errorMsg, variant: 'error' });
    setLoading(false);
  };

  const updateAnalytics = (brand: Brand) => {
    let action = 'Brand selected';
    if (storedBrand?.brandId && brandId !== storedBrand.brandId) action = 'Brand switched';

    analytics.trackActionEvent({ action, userId: user!.userId, brandId: brand.brandId });
    datadog.setUserBrandId(brand);
  };

  useEffect(() => {
    /* unlikely for user to be undefined as this is being handled in ProtectedRoute wrapper */
    if (!user) {
      logout({ returnTo: window.location.origin });
      return;
    }

    /* if brandId is not provided, skip */
    if (!brandId) {
      setLoading(false);
      return;
    }

    const checkForBrandAccess = async () => {
      try {
        const response = await brandApi.getBrandUser({ userId: user.userId, brandId });
        const userHasAccessToBrand = response.status === 200;

        if (!userHasAccessToBrand) {
          showError('You are not authorized to perform this action.');
        }

        return userHasAccessToBrand;
      } catch (err) {
        /* Something happened, let's try again with another brand, maybe? */
        if ((err as AxiosError).response?.status !== 403) showError();
      }

      return false;
    };

    const fetchForBrandInfo = async () => {
      try {
        const response = await brandApi.getBrand({ brandId });

        if (response.status === 200) {
          const brandData = response.data;

          /* do not set store again */
          if (brandId !== storedBrand?.brandId) updateBrandStore(brandData);
          updateAnalytics(brandData);

          return true;
        }
      } catch (err) {
        /* */
      }

      showError();
      return false;
    };

    const processBrandSwitch = async () => {
      /* let's block the app content */
      setLoading(true);

      /* reset user access */
      let userHasAccess = false;

      try {
        userHasAccess = await checkForBrandAccess();

        if (userHasAccess) {
          userHasAccess = await fetchForBrandInfo();
        }
      } catch (err) {
        /* reset user access; something might have happened for this brand */
        userHasAccess = false;
      }

      setHasAccess(userHasAccess);
      setLoading(false);
    };

    processBrandSwitch();
  }, [brandId]);

  const shouldInitializeIntegrations = Boolean(hasAccess && !loading && brandId);

  /* load brand and integrations on brand switch */
  useEffect(() => {
    const fetchIntegrations = async () => {
      /* no need to wait for this when loading the app */
      await initializeIntegrations();
      setIntegrationsReady(true);
    };

    if (shouldInitializeIntegrations) fetchIntegrations();
  }, [shouldInitializeIntegrations]);

  const allIsLoading = loading || integrationLoading;

  useShowSpinner({ show: allIsLoading });

  if (!brandId) return <Navigate to="/brands" />;

  if (loading) return <></>;

  if (!hasAccess) return <Navigate to="/brands" />;

  if (!integrationsReady) return <></>;

  return <Outlet />;
};

export default BrandLoader;
