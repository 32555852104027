import { IntegrationType } from 'constants/integrations';
import React from 'react';
import { FacebookSmall, ShopifySmall, TikTokSmall } from '@innovationdepartment/proxima-ui';
import {
  LookalikeAudienceTableRowProps,
  AudienceTableCellRendererProps,
} from 'types/components/audiences';
import VerticalWrapper from './AudienceRowCell.VerticalWrapper';

const PLATFORMS: { [key in IntegrationType]: React.ReactNode } = {
  [IntegrationType.Facebook]: <FacebookSmall />,
  [IntegrationType.TikTok]: <TikTokSmall />,
  [IntegrationType.Shopify]: <ShopifySmall />,
  [IntegrationType.Klaviyo]: undefined,
  [IntegrationType.Angler]: undefined,
  [IntegrationType.ManualImport]: undefined,
};

const AudienceRowCellPlatform = ({
  row,
}: AudienceTableCellRendererProps<LookalikeAudienceTableRowProps>) => (
  <VerticalWrapper>{row?.integrationType ? PLATFORMS[row?.integrationType] : null}</VerticalWrapper>
);

export default AudienceRowCellPlatform;
