import { Text } from '@innovationdepartment/proxima-ui';
import { MetricCopy } from 'types/components/insights';
import { InsightsMetric } from 'types/insights';

export const performanceDifferencesCopyOptions: MetricCopy[] = [
  {
    metric: InsightsMetric.Roas,
    title: 'ROAS (Return on Ad Spend)',
    copy: (
      <>
        <Text variant="body2" color="neutral90">
          The revenue generated for every dollar spent on advertising.
        </Text>
        <Text variant="body2" color="neutral90">
          ROAS is a key indicator for evaluating the effectiveness of your campaigns.
        </Text>
        <Text variant="body2" color="neutral90">
          This benchmark can help determine the impact of seasonality on your peers&apos;
          advertising efficiency (e.g. seeing more or less fluctuations than your business).
        </Text>
      </>
    ),
  },
  {
    metric: InsightsMetric.Cpa,
    title: 'CPA (Cost per Acquisition)',
    copy: (
      <>
        <Text variant="body2" color="neutral90">
          The average cost incurred to acquire a customer.
        </Text>
        <Text variant="body2" color="neutral90">
          CPA is a key indicator for evaluating the effectiveness of your campaigns.
        </Text>
        <Text variant="body2" color="neutral90">
          This benchmark can help determine the impact of seasonality on your peers&apos;
          advertising efficiency (e.g. seeing more or less fluctuations than your business).
        </Text>
      </>
    ),
  },
  {
    metric: InsightsMetric.Ctr,
    title: 'CTR (Click-Through Rate)',
    copy: (
      <>
        <Text variant="body2" color="neutral90">
          The percentage of people who saw your ad and then clicked on it.
        </Text>
        <Text variant="body2" color="neutral90">
          CTR can help you determine how your creative and messaging strategy is resonating with
          your target audience as compared to your peers and the resulting impact on performance.
        </Text>
      </>
    ),
  },
  {
    metric: InsightsMetric.Cpc,
    title: 'CPC (Cost per Click)',
    copy: (
      <>
        <Text variant="body2" color="neutral90">
          The average cost paid for each click.
        </Text>
        <Text variant="body2" color="neutral90">
          CPC can help you determine how your creative and messaging strategy is resonating with
          your target audience as compared to your peers and the resulting impact on performance.
        </Text>
      </>
    ),
  },
  {
    metric: InsightsMetric.Cpm,
    title: 'CPM (Cost per 1,000 Impressions)',
    copy: (
      <>
        <Text variant="body2" color="neutral90">
          The cost per thousand ad impressions.
        </Text>
        <Text variant="body2" color="neutral90">
          CPM can help you understand if high ad costs are preventing your efficiency from being
          best-in-class. Fluctuations in CPMs can help determine how much competition is in the
          market.
        </Text>
        <Text variant="body2" color="neutral90">
          If your CPMs are lower than the High Performers cohort peers, it could mean that audience
          targeting would improve performance.
        </Text>
      </>
    ),
  },
  {
    metric: InsightsMetric.Cvr,
    title: 'CVR (Conversion Rate)',
    copy: (
      <>
        <Text variant="body2" color="neutral90">
          The percentage of users who completed the desired action (e.g. making a purchase) after
          clicking on the ad.
        </Text>
        <Text variant="body2" color="neutral90">
          CVR is a key metric for evaluating the effectiveness of your campaigns in driving
          conversions.
        </Text>
        <Text variant="body2" color="neutral90">
          While many factors on and off Meta impact CVR, examining CTR and CPM in relation to CVR
          can help determine if your ads are engaging and if you&apos;re targeting the right
          audiences.
        </Text>
      </>
    ),
  },
  {
    metric: InsightsMetric.Spend,
    title: 'Spend',
    copy: (
      <>
        <Text variant="body2" color="neutral90">
          The total amount of budget spent across campaigns, ad sets, and ads.
        </Text>
        <Text variant="body2" color="neutral90">
          This benchmark can help you see if your budget allocation between peaks and low seasons is
          aligned with High Performers performers in your category.
        </Text>
      </>
    ),
  },
];

export const traditionalBenchmarksCopyOptions: MetricCopy[] = [
  {
    metric: InsightsMetric.Cpa,
    title: 'CPA (Cost per Acquisition)',
    copy: (
      <>
        <Text variant="body2" color="white">
          The average cost incurred to acquire a customer. Calculated by dividing the total ad spend
          by the number of conversions.
        </Text>
        <Text variant="body2" color="white">
          High Performers: The median value across the best performers (top 25%) in your peer group.
          Average Performers: The median value across the middle 50% of performers in your peer
          group.
        </Text>
      </>
    ),
  },
  {
    metric: InsightsMetric.Roas,
    title: 'ROAS (Return on Ad Spend)',
    copy: (
      <>
        <Text variant="body2" color="white">
          The revenue generated for every dollar spent on advertising. Calculated by dividing the
          total revenue attributed to the ads by the total ad spend.{' '}
        </Text>
        <Text variant="body2" color="white">
          High Performers: The median value across the best performers (top 25%) in your peer group.
          Average Performers: The median value across the middle 50% of performers in your peer
          group.
        </Text>
      </>
    ),
  },
  {
    metric: InsightsMetric.Ctr,
    title: 'CTR (Click-Through Rate)',
    copy: (
      <>
        <Text variant="body2" color="white">
          The percentage of people who saw your ad and then clicked on it. Calculated by dividing
          the total number of clicks by the total number of impressions.
        </Text>
        <Text variant="body2" color="white">
          High Performers: The median value across the best performers (top 25%) in your peer group.
          Average Performers: The median value across the middle 50% of performers in your peer
          group.
        </Text>
      </>
    ),
  },
  {
    metric: InsightsMetric.Cpc,
    title: 'CPC (Cost per Click)',
    copy: (
      <>
        <Text variant="body2" color="white">
          The average cost paid for each click. Calculated by dividing the total ad spend by the
          number of clicks.
        </Text>
        <Text variant="body2" color="white">
          High Performers: The median value across the best performers (top 25%) in your peer group.
          Average Performers: The median value across the middle 50% of performers in your peer
          group.
        </Text>
      </>
    ),
  },
  {
    metric: InsightsMetric.Cpm,
    title: 'CPM (Cost per 1,000 Impressions)',
    copy: (
      <>
        <Text variant="body2" color="white">
          The cost per thousand ad impressions. Calculated by dividing the total ad spend by the
          number of impressions, multiplied by 1,000.
        </Text>
        <Text variant="body2" color="white">
          High Performers: The median value across the best performers (top 25%) in your peer group.
          Average Performers: The median value across the middle 50% of performers in your peer
          group.
        </Text>
      </>
    ),
  },
  {
    metric: InsightsMetric.Cvr,
    title: 'CVR (Conversion Rate)',
    copy: (
      <>
        <Text variant="body2" color="white">
          The percentage of users who completed the desired action (e.g. making a purchase) after
          clicking on the ad. Calculated by dividing the number of conversions by the number of
          clicks.
        </Text>
        <Text variant="body2" color="white">
          High Performers: The median value across the best performers (top 25%) in your peer group.
          Average Performers: The median value across the middle 50% of performers in your peer
          group.
        </Text>
      </>
    ),
  },
];
