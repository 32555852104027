import { AnglerIntegration, Text } from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';
import MainContentDescription from '../IntegrationsModal/LayoutComponents/MainContentDescription';

const content: { title: string; description: React.ReactNode }[] = [
  {
    title: `AI Audiences 🤝 Predictive CAPI`,
    description: (
      <>
        Let&apos;s fix the broken feedback loop between your storefront&apos;s raw data and Meta
        with Predictive CAPI.
        <br />
        <br />
        <Text variant="subhead3" isTermina>
          How?
        </Text>
        <br />
        Only send high-value events to Meta, filtering out the rest and training the optimization
        algorithm to drive more of the revenue outcomes you want.
        <br />
        <br />
        <Text variant="subhead3" isTermina>
          Proxima supercharges your Predictive CAPI pixel
        </Text>
        <br />
        With Proxima&apos;s AI Audiences, you&apos;ll bypass the usual pixel learning curve and get
        faster results.
        <br />
        <br />
        We&apos;ll deploy a unique pixel (designed to prioritize and amplify high-value events) in
        tandem with AI Audiences, speeding up Meta&apos;s algorithm learnings to improve campaign
        targeting and performance.
      </>
    ),
  },
];

const GraphContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  * > svg {
    width: 100%;
  }
`;

const AnglerIntegrationModalMainView = () => (
  <>
    {content.map(({ title, description }) => (
      <MainContentDescription key={title} title={title} description={description} />
    ))}
    <GraphContainer>
      <AnglerIntegration />
    </GraphContainer>
  </>
);

export default AnglerIntegrationModalMainView;
