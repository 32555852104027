import styled from '@emotion/styled';
import { colors, Text } from '@innovationdepartment/proxima-ui';

export const NoDataContainer = styled.div`
  background: ${colors.white};
  border-radius: 8px;
  height: 100%;
  width: 100%;
`;

export const CenteredText = styled(Text)`
  line-height: 201px;
  text-align: center;
`;
