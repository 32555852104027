import { MagicStars } from '@innovationdepartment/proxima-ui';
import { InsightsInterval, InsightsMetric } from 'types/insights';

export const metricOptions = [
  {
    label: 'ROAS',
    value: InsightsMetric.Roas,
    icon: <MagicStars />,
  },
  {
    label: 'CTR',
    value: InsightsMetric.Ctr,
    icon: <MagicStars />,
  },
  {
    label: 'CPM',
    value: InsightsMetric.Cpm,
    icon: <MagicStars />,
  },
  {
    label: 'CPA',
    value: InsightsMetric.Cpa,
    icon: <MagicStars />,
  },
  {
    label: 'CPC',
    value: InsightsMetric.Cpc,
    icon: <MagicStars />,
  },
  // TODO (Milecia): hiding this until the data gets fixed
  // {
  //   label: 'CVR',
  //   value: InsightsMetric.Cvr,
  //   icon: <MagicStars />,
  // },
];

export const intervalOptions = [
  {
    label: 'By Month',
    value: InsightsInterval.Month,
  },
  {
    label: 'By Day',
    value: InsightsInterval.Day,
  },
  {
    label: 'By Quarter',
    value: InsightsInterval.Quarter,
  },
  {
    label: 'By Year',
    value: InsightsInterval.Year,
  },
  {
    label: 'By Week',
    value: InsightsInterval.Week,
  },
];
