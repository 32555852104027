import { BrandIntegrationStatus, IntegrationType } from 'constants/integrations';
import { IntegrationListItem } from './types';
import {
  AddCircle,
  Angler,
  Klaviyo,
  LabelStatusProps,
  MetaNoBorder,
  Shopify,
  Text,
  Upload,
} from '@innovationdepartment/proxima-ui';
import styled from '@emotion/styled';
import { useFeatureFlag } from 'hooks';

const UploadHeaderIcon = styled.div`
  height: 36px;
  width: 36px;
`;

const SmallUploadIcon = styled.div`
  display: flex;
  align-items: center;

  > svg {
    height: 16px;
    width: 16px;
  }
`;

type GetIntegrationsProps = {
  loading: boolean;
  isFileUploadAllowed: boolean;
  onClick: (type: IntegrationType) => void;
  getStatus: (type: IntegrationType) => BrandIntegrationStatus | undefined;
};

const disconnected: LabelStatusProps = {
  label: 'Disconnected',
  variant: 'error',
};

const incomplete: LabelStatusProps = {
  label: 'Incomplete',
  variant: 'warning',
};

const termsNotAccepted: LabelStatusProps = {
  label: 'Terms not accepted',
  variant: 'warning',
};

const getButtonLabel = (status: BrandIntegrationStatus | undefined) => {
  if (status === undefined) return 'Connect';
  if (status === BrandIntegrationStatus.Connected) return 'Connected';
  if (status === BrandIntegrationStatus.Disconnected) return 'Reconnect';
  if (status === BrandIntegrationStatus.Incomplete) return 'Finish Connecting';
  if (status === BrandIntegrationStatus.TermsRequired) return 'Accept Terms';
  return 'Connect';
};

const getLabelStatus = (
  status: BrandIntegrationStatus | undefined,
  type: IntegrationType
): LabelStatusProps | undefined => {
  if (status === BrandIntegrationStatus.Connected) return undefined;
  if (status === BrandIntegrationStatus.Disconnected) return disconnected;
  if (status === BrandIntegrationStatus.Incomplete) return incomplete;

  if (type === IntegrationType.Facebook) {
    if (status === BrandIntegrationStatus.TermsRequired) return termsNotAccepted;
  }

  return undefined;
};

const getVariant = (status: BrandIntegrationStatus | undefined) => {
  if (status === BrandIntegrationStatus.Connected) return 'success';
  if (status === BrandIntegrationStatus.Disconnected) return 'error';
  if (status === BrandIntegrationStatus.Incomplete) return 'incomplete';
  if (status === BrandIntegrationStatus.TermsRequired) return 'incomplete';
  return 'default';
};

export default function getIntegrationsProps({
  loading,
  onClick,
  getStatus,
  isFileUploadAllowed,
}: GetIntegrationsProps): IntegrationListItem[] {
  const { anglerIntegration } = useFeatureFlag();

  /** Shopify */
  const shopifyStatus = getStatus(IntegrationType.Shopify);
  const shopifyButtonLabel = getButtonLabel(shopifyStatus);
  const shopifyLabelStatus = loading
    ? undefined
    : getLabelStatus(shopifyStatus, IntegrationType.Shopify);

  /** Facebook */
  const fbStatus = getStatus(IntegrationType.Facebook);
  const fbButtonLabel = getButtonLabel(fbStatus);
  const fbLabelStatus = loading ? undefined : getLabelStatus(fbStatus, IntegrationType.Facebook);

  /** Klaviyo */
  const klaviyoStatus = getStatus(IntegrationType.Klaviyo);
  const klaviyoButtonLabel = getButtonLabel(klaviyoStatus);
  const klaviyoLabelStatus = getLabelStatus(klaviyoStatus, IntegrationType.Klaviyo);

  /** Angler */
  const anglerStatus = getStatus(IntegrationType.Angler);
  const anglerButtonLabel = 'Go Now';

  const integrations: IntegrationListItem[] = [
    /** Facebook */
    {
      loading,
      headerProps: {
        icon: <MetaNoBorder />,
        labelStatus: fbLabelStatus,
      },
      footerProps: {
        actionButton: {
          loading,
          variant: getVariant(fbStatus),
          label: fbButtonLabel,
          onClick: () => onClick(IntegrationType.Facebook),
          icon: <AddCircle />,
        },
      },
      type: IntegrationType.Facebook,
      label: (
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <Text variant="h6" color="neutral90" isTermina={false}>
            Meta
          </Text>
          <div style={{ height: 20 }}>
            <Text variant="tag1Semibold" color="neutral70">
              (Required)
            </Text>
          </div>
        </div>
      ),
      bodyContent: `Seamlessly add audiences to your ad account and benchmark performance.`,
    },
    /** Shopify */
    {
      loading,
      headerProps: {
        icon: <Shopify />,
        labelStatus: shopifyLabelStatus,
        pill: shopifyLabelStatus ? undefined : { label: '30% better results', variant: 'success' },
      },
      footerProps: {
        actionButton: {
          loading,
          variant: getVariant(shopifyStatus),
          label: shopifyButtonLabel,
          onClick: () => onClick(IntegrationType.Shopify),
          icon: <AddCircle />,
        },
      },
      type: IntegrationType.Shopify,
      label: 'Shopify',
      bodyContent: `Sync conversion data and enhance targeting algorithms for better results.`,
    },
    /** Klaviyo */
    {
      loading,
      headerProps: {
        icon: <Klaviyo />,
        labelStatus: klaviyoLabelStatus,
        pill: klaviyoLabelStatus ? undefined : { label: 'Early Access', variant: 'info' },
      },
      footerProps: {
        actionButton: {
          loading,
          variant: getVariant(klaviyoStatus),
          label: klaviyoButtonLabel,
          onClick: () => onClick(IntegrationType.Klaviyo),
          icon: <AddCircle />,
        },
      },
      type: IntegrationType.Klaviyo,
      label: 'Klaviyo',
      bodyContent: `Enable enhanced customer insights and advanced targeting models.`,
    },
    /** Angler */
    {
      loading,
      headerProps: {
        labelStatus: undefined,
        icon: <Angler />,
      },
      footerProps: {
        actionButton: {
          loading,
          variant: getVariant(anglerStatus),
          label: anglerButtonLabel,
          onClick: () => onClick(IntegrationType.Angler),
          icon: <></>,
        },
      },
      type: IntegrationType.Angler,
      label: 'Angler AI',
      bodyContent: `Expedite the training of your Predictive CAPI pixel and maximize your signal.`,
    },
  ];

  /** Manual Upload */
  const manualUploadIntegration: IntegrationListItem = {
    loading,
    headerProps: {
      icon: (
        <UploadHeaderIcon>
          <Upload />
        </UploadHeaderIcon>
      ),
      labelStatus: undefined,
      pill: undefined,
    },
    footerProps: {
      actionButton: {
        loading,
        variant: 'default',
        label: 'Secure Upload',
        onClick: () => onClick(IntegrationType.ManualImport),
        icon: (
          <SmallUploadIcon>
            <Upload />
          </SmallUploadIcon>
        ),
      },
    },
    type: IntegrationType.ManualImport,
    label: 'Manual Import',
    bodyContent: `Don't have Shopify? Upload your customer list as a CSV file.`,
  };

  if (isFileUploadAllowed) integrations.push(manualUploadIntegration);

  // remove Angler due to feature flag
  if (!anglerIntegration)
    return integrations.filter((integration) => integration.type !== IntegrationType.Angler);

  return integrations;
}
