import { DatePicker } from '@innovationdepartment/proxima-ui';
import { FilterValues, TopAdsFiltersProps } from '../../types';

const TopAdsFiltersDatePicker = (props: TopAdsFiltersProps) => {
  const { filters, onFilterChange } = props;
  return (
    <DatePicker
      disabled // TODO(Jenky): temporary until we have filters working properly
      onDateRangeSelect={(dates) => onFilterChange('dateRange', dates as FilterValues['dateRange'])}
      showSelectWithDateRange={false}
      dateRange={filters.dateRange}
    />
  );
};

export default TopAdsFiltersDatePicker;
