import styled from '@emotion/styled';
import { colors, Help, MagicStars, Text } from '@innovationdepartment/proxima-ui';
import { META_BENCHMARKS_HELP_GUIDE_URL } from 'constants/urls';
import TrialEndedPillView from 'ui/TrialEnded/TrialEnded.Pill.View';
import { getDaysRemaining } from 'utils/getDaysRemaining';

const GradientTag = styled.div`
  border-radius: 100px;
  background: linear-gradient(90deg, ${colors.proximaGreen40} 0%, #89d0e9 100%);
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 4px 8px;
  width: 67px;
`;

const HeaderContainer = styled.div`
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.neutral20};
  display: flex;
  height: 69px;
  padding: 12px 32px;
  justify-content: space-between;
  align-items: center;
`;

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const BrandNameSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const HeaderRightSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;

  .trial-ended-pill {
    position: static;
  }
`;

const HelpIconContainer = styled.a`
  border-radius: 18px;
  border: 1px solid ${colors.neutral20};
  display: flex;
  width: 36px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const IconLabelContainer = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;

  > svg {
    height: 16px;
    width: 16px;
  }
`;

type InsightsHeaderProps = {
  brandName: string;
  trialEndDate: string;
  onOpenManageSubscriptionModal: () => void;
};

const InsightsHeader = (props: InsightsHeaderProps) => {
  const { brandName, trialEndDate, onOpenManageSubscriptionModal } = props;

  // Convert unix timestamp into a date
  const trialEndDateConverted = new Date(trialEndDate);

  const daysRemaining = getDaysRemaining(trialEndDateConverted);

  const showTrialEndingPill = daysRemaining <= 30 && daysRemaining > 0;

  return (
    <HeaderContainer>
      <HeaderTitle>
        <Text color="neutral70" variant="h4" isTermina>
          Benchmarks | Meta
        </Text>
        <GradientTag>
          <IconLabelContainer>
            <MagicStars />
            <Text variant="tag2Semibold" color="neutral80">
              Beta
            </Text>
          </IconLabelContainer>
        </GradientTag>
      </HeaderTitle>
      <HeaderRightSection>
        {showTrialEndingPill && (
          <TrialEndedPillView
            daysRemaining={daysRemaining}
            onOpenManageSubscriptionModal={onOpenManageSubscriptionModal}
          />
        )}
        <BrandNameSection>
          <Text variant="body2Semibold" color="neutral70">
            {brandName}
          </Text>
          <HelpIconContainer href={META_BENCHMARKS_HELP_GUIDE_URL} target="_blank">
            <Help />
          </HelpIconContainer>
        </BrandNameSection>
      </HeaderRightSection>
    </HeaderContainer>
  );
};

export default InsightsHeader;
