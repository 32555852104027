import * as date from 'date-fns';

const getDaysRemaining = (trialEndDate: Date) => {
  // This is to account for the time included in the trial end date passed from Stripe
  const differenceInHours = date.differenceInHours(trialEndDate, new Date());

  const daysRemaining = Math.ceil(differenceInHours / 24);

  return daysRemaining;
};

export { getDaysRemaining };
