import { Angler } from '@innovationdepartment/proxima-ui';
import { AnglerIntegrationModalProps } from 'types/components/integrations';
import IntegrationsModal from '../IntegrationsModal';
import AnglerIntegrationModalSideContent from './AnglerIntegrationModal.SideContent';
import AnglerIntegrationModalMainView from './AnglerIntegrationModal.MainContent';

const AnglerIntegrationModalView: React.FC<AnglerIntegrationModalProps> = (props) => {
  const { open, onClose, onConnectAnglerClick } = props;

  const handleConnectAnglerClick = () => {
    onConnectAnglerClick();
    onClose();
  };

  return (
    <IntegrationsModal
      icon={<Angler />}
      title="Angler AI"
      open={open}
      onClose={onClose}
      sideContent={
        <AnglerIntegrationModalSideContent onConnectAnglerClick={handleConnectAnglerClick} />
      }
    >
      <AnglerIntegrationModalMainView />
    </IntegrationsModal>
  );
};

export default AnglerIntegrationModalView;
