import styled from '@emotion/styled';
import { Button, colors, Tabs } from '@innovationdepartment/proxima-ui';
import { FilterValues, TopAdsTabsProps } from '../../types';
import { PROXIMA_SHARE_FEEDBACK_URL } from 'constants/urls';
import LoadingBar from 'ui/LoadingBar';

const TabsContainer = styled.div`
  height: 60px;
  border-bottom: 1px solid ${colors.neutral20};
  position: relative;

  & .loading-bar {
    position: absolute;
    bottom: -5px;
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  height: 100%;

  & > div {
    & :is(button) {
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    height: calc(100% + 1px); /* +1px for the bottom border */
  }

  & > button {
    /* share feedback button */
    align-self: center;
  }
`;

const AVAILABLE_TABS: { key: FilterValues['tab']; label: string }[] = [
  {
    key: 'all',
    label: 'Overall',
  },
  {
    key: 'roas',
    label: 'ROAS',
  },
  {
    key: 'ctr',
    label: 'CTR',
  },
];

const TopAdsTabs = (props: TopAdsTabsProps) => {
  const { onFilterChange, loading, filters } = props;
  return (
    <TabsContainer>
      <TabsWrapper className="top-ads-tabs">
        <Tabs
          disable={loading}
          tab={filters.tab}
          onChange={(t: string) => onFilterChange('tab', t as FilterValues['tab'])}
          tabs={AVAILABLE_TABS}
        />
        <Button
          label="Share Feedback"
          overrideColors={{
            backgroundColor: 'linear-gradient(to right, #45EDBA, #89D0E9);',
            textColor: 'neutral100',
          }}
          onClick={() =>
            window.open(PROXIMA_SHARE_FEEDBACK_URL, '_blank', 'rel="noreferrer noopener"')?.focus()
          }
        />
      </TabsWrapper>
      <LoadingBar className="loading-bar" loading={loading} />
    </TabsContainer>
  );
};

export default TopAdsTabs;
