import { Text } from '@innovationdepartment/proxima-ui';
import { dollarUnitSymbol, percentUnitSymbol } from 'components/Insights/insightsHelpers';
import { InsightsColumnData, TrendsFooterCellRendererProps } from 'types/insights';

const calculateColumnAverage = (columnData: number[]) => {
  const total = columnData.reduce((acc, curr) => acc + curr, 0);

  const count = columnData.length;

  const avg = (total / count).toFixed(2);

  const isNotANumber = Number.isNaN(avg) || avg === 'NaN';

  return { avg, isNotANumber };
};

const trendsFooterRenderer = (props: TrendsFooterCellRendererProps<InsightsColumnData>) => {
  const { columnData, header } = props;

  const columnsWithAverages = ['cpm', 'ctr', 'cpa', 'roas', 'cpc'];

  if (!columnsWithAverages.includes(header!)) return null;

  const { avg, isNotANumber } = calculateColumnAverage(columnData as number[]);

  if (isNotANumber) return null;

  return (
    <div style={{ display: 'flex', flexFlow: 'column' }}>
      <Text variant="body2Semibold" color="neutral90">
        {`${dollarUnitSymbol(header!)}${avg}${percentUnitSymbol(header!)}`}
      </Text>
      <Text variant="tag2Semibold" color="neutral70">
        Average
      </Text>
    </div>
  );
};

export default trendsFooterRenderer;
