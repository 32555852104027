import axios from 'axios';
import { useProximaSDK } from 'hooks';
import CustomerListUploadModalView from './CustomerListUploadModal.View';
import { useBrandStore } from 'stores';
import { BannerMessage, UploadStatus } from 'types/brandCustomerSettings';

type CustomerListUploadModalProps = {
  open: boolean;
  onClose: () => void;
};

const FILE_SIZE_LIMIT = 50 * 1024 * 1024; // 50 MB

const validationMessages = (fileName: string) => ({
  serverError: `${fileName} upload failed.`,
  fileNameError: 'Upload failed: File name cannot contain special characters.',
  fileSizeError: 'Upload failed: File size must be 50 MB or less.',
  fileTypeError: 'Upload failed: File must be a CSV.',
  success: `${fileName} uploaded! We will email you once the file is verified.`,
});

const CustomerListUploadModal = (props: CustomerListUploadModalProps) => {
  const { open, onClose } = props;
  const { brand } = useBrandStore();
  const brandsApi = useProximaSDK('BrandsApi');

  const handleCustomerListUpload = async (file: File): Promise<BannerMessage | undefined> => {
    // do file validation
    const fileValidationMessages = validationMessages(file.name);
    const specialCharacterRegex = /^[\w-.()]+(.csv)$/;

    if (file.type !== 'text/csv')
      return { message: fileValidationMessages.fileTypeError, type: 'error' };

    if (file.size >= FILE_SIZE_LIMIT)
      return { message: fileValidationMessages.fileSizeError, type: 'error' };

    if (!specialCharacterRegex.test(file.name))
      return { message: fileValidationMessages.fileNameError, type: 'error' };

    // get temp s3 bucket url to upload file
    const { data: uploadBatchResponse, status: uploadBatchStatus } =
      await brandsApi.uploadCustomerBatch({
        brandId: brand.brandId,
        uploadCustomerBatchRequest: {
          fileName: file.name,
        },
      });

    if (uploadBatchStatus !== 201)
      return { message: fileValidationMessages.serverError, type: 'error' };

    // make s3 upload request
    // TODO (Milecia): waiting on this https://github.com/InnovationDepartment/proxima.brands.customer/pull/39/files to get merged and the SDK to be updated
    // @ts-ignore
    const { id: batchId, uploadFilePolicy } = uploadBatchResponse;

    const formData = new FormData();
    formData.append('key', uploadFilePolicy.fields.key);
    formData.append('policy', uploadFilePolicy.fields.Policy);
    formData.append('bucket', uploadFilePolicy.fields.bucket);
    formData.append('X-Amz-Algorithm', uploadFilePolicy.fields['X-Amz-Algorithm']);
    formData.append('X-Amz-Credential', uploadFilePolicy.fields['X-Amz-Credential']);
    formData.append('X-Amz-Date', uploadFilePolicy.fields['X-Amz-Date']);
    formData.append('X-Amz-Security-Token', uploadFilePolicy.fields['X-Amz-Security-Token']);
    formData.append('X-Amz-Signature', uploadFilePolicy.fields['X-Amz-Signature']);
    formData.append('file', file);

    const s3UploadResponse = await axios.post(uploadFilePolicy.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (s3UploadResponse.status !== 204) {
      // set file upload status
      await brandsApi.uploadCustomerBatchStatus({
        brandId: brand.brandId,
        batchId: batchId!,
        uploadCustomerBatchStatusRequest: { uploadStatus: UploadStatus.Failed },
      });

      return { message: fileValidationMessages.serverError, type: 'error' };
    }

    if (s3UploadResponse.status === 204) {
      // set file upload status
      await brandsApi.uploadCustomerBatchStatus({
        brandId: brand.brandId,
        batchId: batchId!,
        uploadCustomerBatchStatusRequest: { uploadStatus: UploadStatus.Uploaded },
      });

      return { message: fileValidationMessages.success, type: 'success' };
    }

    return undefined;
  };

  return (
    <CustomerListUploadModalView
      open={open}
      onClose={onClose}
      handleCustomerListUpload={handleCustomerListUpload}
    />
  );
};

export default CustomerListUploadModal;
