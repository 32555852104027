import styled from '@emotion/styled';
import { colors, Text } from '@innovationdepartment/proxima-ui';
import ModalBase from 'ui/ModalBase';
import { TopAdsCreativePreviewModalProps } from './types';
import TopAdsCreativePreviewPreview from './TopAds.CreativePreviewPreview.Container';
import TopAdsCreativePreviewAdDetails from './TopAds.CreativePreviewAdDetails';

const MainContentWrapper = styled.div`
  border-top: 1px solid ${colors.neutral10};
  max-width: 932px;
  display: flex;
`;

const TopAdsCreativePreviewModal = (props: TopAdsCreativePreviewModalProps) => {
  const { open, onClose } = props;

  return (
    <ModalBase
      allowCloseOnClickOutside
      variant="default"
      title={
        <Text variant="h4" isTermina={false}>
          Ad Quickview
        </Text>
      }
      open={open}
      onClose={onClose}
    >
      <MainContentWrapper>
        <TopAdsCreativePreviewPreview {...props} />
        <TopAdsCreativePreviewAdDetails {...props} />
      </MainContentWrapper>
    </ModalBase>
  );
};

export default TopAdsCreativePreviewModal;
