import { InsightsMetric } from 'types/insights';
import PercentChange from '../Layout/Trends.PercentChange';

const isNegative = (value: number) => {
  const sign = Math.sign(value);
  return sign === -1;
};

const displayValue = (metricValue: number | string) => {
  const isNotANumber = Number.isNaN(metricValue) || metricValue === 'NaN';
  const isZero = metricValue === 0;

  return isNotANumber || isZero ? '-' : `${Number(metricValue).toFixed(2)}%`;
};

export const CpaChangeCell = ({
  cpaChange,
}: {
  cpaChange: { flavorCategoryCpaChange: number; brandCpaChange: number };
}) => {
  const { flavorCategoryCpaChange, brandCpaChange } = cpaChange;

  const flavorArrowDirection = isNegative(flavorCategoryCpaChange) ? 'down' : 'up';
  const brandArrowDirection = isNegative(brandCpaChange) ? 'down' : 'up';

  return (
    <PercentChange
      metric={InsightsMetric.Cpa}
      brandChange={displayValue(brandCpaChange)}
      flavorCategoryChange={displayValue(flavorCategoryCpaChange)}
      brandArrowDirection={brandArrowDirection}
      flavorArrowDirection={flavorArrowDirection}
    />
  );
};

export const RoasChangeCell = ({
  roasChange,
}: {
  roasChange: { flavorCategoryRoasChange: number; brandRoasChange: number };
}) => {
  const { flavorCategoryRoasChange, brandRoasChange } = roasChange;

  const flavorArrowDirection = isNegative(flavorCategoryRoasChange) ? 'down' : 'up';
  const brandArrowDirection = isNegative(brandRoasChange) ? 'down' : 'up';

  return (
    <PercentChange
      metric={InsightsMetric.Roas}
      brandChange={displayValue(brandRoasChange)}
      flavorCategoryChange={displayValue(flavorCategoryRoasChange)}
      brandArrowDirection={brandArrowDirection}
      flavorArrowDirection={flavorArrowDirection}
    />
  );
};
