import { CreativeAd, CreativeType } from 'types/components/creatives';

const FB_PERMALINK_URL = 'https://www.facebook.com/plugins/video.php?href=https://www.facebook.com';

export const getCreativeAsset = (ad: CreativeAd) => {
  const { creativeType, creativeAssets } = ad;
  const isVideo = creativeType === CreativeType.VIDEO;
  const DEFAULT_ASSET =
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQc_8xuBVttgoIei52J4NiD6ve1Q0nB588Fvg&s';

  const foundCreativeAsset = creativeAssets?.find(
    (creativeAsset) => creativeAsset.permalinkType === creativeType
  )?.permalinkUrl;
  let thumbnail = creativeAssets?.find(
    (creativeAsset) => creativeAsset.permalinkType === 'PHOTO'
  )?.permalinkUrl;

  if (!foundCreativeAsset) {
    return {
      type: creativeType,
      asset: DEFAULT_ASSET,
      thumbnail: thumbnail ?? DEFAULT_ASSET,
    };
  }

  if (isVideo) {
    const asset = creativeAssets?.find((videoAsset) => !!videoAsset.videoMeta?.picture);
    const videoMeta = asset?.videoMeta;
    const preferredThumbnail = videoMeta?.thumbnails?.find((t) => t.is_preferred);

    /* choose the picture, if available */
    thumbnail = asset?.videoMeta?.picture ?? thumbnail;
    /* choose preferred thumbnail */
    thumbnail = preferredThumbnail?.uri ?? thumbnail;

    return {
      type: creativeType,
      asset: foundCreativeAsset ? `${FB_PERMALINK_URL}${foundCreativeAsset}` : DEFAULT_ASSET,
      thumbnail: thumbnail || DEFAULT_ASSET,
    };
  }

  return {
    type: creativeType,
    asset: thumbnail || DEFAULT_ASSET,
    thumbnail: thumbnail || DEFAULT_ASSET,
  };
};
