import { DEFAULT_FRONTEND_TIMEZONE } from 'constants/defaults';
import * as dateFns from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';

const dateFormatter = (date: Date) =>
  dateFnsTz.format(date, 'MMM dd, yyyy', { timeZone: DEFAULT_FRONTEND_TIMEZONE });
const timeFormatter = (date: Date) =>
  dateFnsTz.format(date, 'h:mm a', { timeZone: DEFAULT_FRONTEND_TIMEZONE });

const formatDate = (date: string | Date, includeTime = false) => {
  let newDate = date;
  if (typeof newDate === 'string') newDate = new Date(newDate);

  if (includeTime) return `${dateFormatter(newDate)} at ${timeFormatter(newDate)}`;

  return dateFormatter(newDate);
};

const formatDateFromIso = (date: string) => formatDate(new Date(date));

const formatDateToIsoWithoutTime = (date: Date) =>
  dateFns.formatISO(date, { representation: 'date' });

const formatMonthDayDate = (date: string | Date) => {
  let newDate = date;
  if (typeof newDate === 'string') newDate = new Date(newDate);

  const monthDayFormatOptions: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
  };

  const monthDayFormatter = new Intl.DateTimeFormat('en-US', monthDayFormatOptions).format;
  return monthDayFormatter(newDate);
};

const formatMonthYearDate = (date: string | Date) => {
  let newDate = date;
  if (typeof newDate === 'string') newDate = new Date(newDate);

  const monthYearFormatOptions: Intl.DateTimeFormatOptions = {
    month: 'short',
    year: '2-digit',
  };

  const monthYearFormatter = new Intl.DateTimeFormat('en-US', monthYearFormatOptions).format;
  return monthYearFormatter(newDate);
};

export {
  formatDate,
  formatMonthDayDate,
  formatMonthYearDate,
  formatDateFromIso,
  formatDateToIsoWithoutTime,
};
