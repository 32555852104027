import { AxiosResponse } from 'axios';
import type { UserBrandAccess, PartialProfile } from '@innovationdepartment/proxima-sdk-axios';
import { useEffect, useState } from 'react';
import { useToaster } from '@innovationdepartment/proxima-ui';
import { useUserStore } from 'stores';

import { useIntegrations, useProximaSDK, useShowSpinner } from 'hooks';
import InviteUserModal from 'ui/Modals/InviteUserModal';
import UsersView from './Users.View';
import userRowRender from './userRowRender';
import { useParams } from 'react-router-dom';
import { formatUserData, getCurrentAndPreviousMetaOwner } from './usersUtils';

const Users = () => {
  const { brandId } = useParams();
  const brandsApi = useProximaSDK('BrandsApi', { brandId });
  const userProfilesApi = useProximaSDK('UsersApi', { brandId });
  const { getAllIntegrationsByType } = useIntegrations();

  const { showToaster } = useToaster();
  const { user: loggedInUser } = useUserStore();

  const [loading, setLoading] = useState(true);
  const [hoverUserId, setHoverUserId] = useState<string | null>(null);
  const [users, setUsers] = useState<PartialProfile[]>([]);
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);

  useShowSpinner({ show: loading });

  const fetchBrandUsers = async () => {
    setLoading(true);

    // get users in brand
    let response: AxiosResponse<UserBrandAccess[], any>;
    try {
      response = await brandsApi.getAllUsersForBrand({ brandId: brandId as string });
    } catch (e) {
      setLoading(false);
      return;
    }

    setLoading(false);

    let usersFromBrand: UserBrandAccess[] = [];
    const brandUsers: PartialProfile[] = [];

    if (response.status === 200) {
      usersFromBrand = response.data;
    }

    if (usersFromBrand.length) {
      // get user details
      const userIds = usersFromBrand.map((u) => u.userId).join(',');
      const userProfilesRes = await userProfilesApi.getProfileBatch({ userId: userIds });

      if (userProfilesRes.status === 200) {
        const profiles = userProfilesRes.data;
        profiles.forEach((profile: any) => {
          const user =
            usersFromBrand.find((userFromBrand) => profile.userId === userFromBrand.userId) ?? {};
          brandUsers.push({ ...user, ...profile });
        });
      }
    }

    setUsers(brandUsers);
  };

  const integrations = getAllIntegrationsByType();

  const [metaOwner, previousMetaOwner] = getCurrentAndPreviousMetaOwner(integrations, users);

  type FormatUserSchema = Parameters<typeof formatUserData>[0];
  const formatUsers = (brandUser: any) => {
    const actionMenuProps = {
      showActions: brandUser.userId === hoverUserId,
      userId: brandUser.userId,
      onClose: () => setHoverUserId(null),
      setLoading,
      fetchBrandUsers,
      showToaster,
    };
    const userData: FormatUserSchema = {
      ...brandUser,
      menuProps: actionMenuProps,
      isCurrentUser: brandUser.userId === loggedInUser?.userId,
      isFBOwner: brandUser.userId === metaOwner,
      isFBPreviousOwner: brandUser.userId === previousMetaOwner,
    };

    return formatUserData(userData);
  };

  useEffect(() => {
    if (!brandId) return;
    fetchBrandUsers();
  }, [brandId]);

  const handleOpenModal = () => setInviteUserModalOpen(true);
  const handleCloseModal = (invitedEmail?: string) => {
    setInviteUserModalOpen(false);
    if (invitedEmail) fetchBrandUsers();
  };

  const usersListConfig: any = {
    cellRenderer: userRowRender,
    onRowMouseEnter: (row: any) => {
      setHoverUserId(row.userId);
    },
    onRowMouseLeave: () => {
      setHoverUserId(null);
    },
    formatter: formatUsers,
    columns: {
      columnId: 'userId',
      order: ['name', 'email', 'status', 'role', 'lastLoginAt', 'menu'],
      name: { label: 'Name', width: 200 },
      email: { label: 'Email address' },
      status: { label: 'Status' },
      role: { label: 'Role' },
      lastLoginAt: { label: 'Last login date', width: 154 },
      menu: { label: '', width: 56 },
    },
  };

  return (
    <>
      <UsersView users={users} addUserButtonClick={handleOpenModal} tableConfig={usersListConfig} />
      <InviteUserModal open={inviteUserModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default Users;
