import * as analytics from 'lib/analytics';
import { ANGLER_SIGN_UP_URL } from 'constants/urls';
import { useUserStore } from 'stores';
import { useParams } from 'react-router-dom';

const useAngler = () => {
  const { user } = useUserStore();
  const { brandId } = useParams<{ brandId: string }>();

  const onConnectAnglerClick = () => {
    analytics.trackActionEvent({
      action: 'Clicked',
      userId: user?.userId!,
      brandId,
      location: 'AnglerIntegrationModal',
      element: 'AnglerGetStartedButton',
    });

    window.open(ANGLER_SIGN_UP_URL, '_blank');
  };

  return {
    onConnectAnglerClick,
  };
};

export default useAngler;
