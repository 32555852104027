import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from 'types/featureFlag';

const useFeatureFlag = () => {
  const flags = useFlags<FeatureFlags>();

  return flags;
};

export default useFeatureFlag;
