import { Text, colors } from '@innovationdepartment/proxima-ui';
import { Close } from '@mui/icons-material';
import styled from '@emotion/styled';
import { ModalBaseProps } from './types';

const HeaderWrapper = styled.div<Pick<ModalBaseProps, 'header'> & { removeStyles: boolean }>`
  display: flex;
  padding: ${({ header }) => (header?.padding === 'normal' ? '24px 32px' : '16px')};
  ${({ removeStyles }) => {
    if (removeStyles) return null;
    return `
      background-color: ${colors.neutral5};
      border-bottom: 1px solid ${colors.neutral20};
    `;
  }}
`;

const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const IconWrapper = styled.div`
  height: 48px;
  width: 48px;
  background-color: ${colors.white};
  border: 1px solid ${colors.neutral20};
  border-radius: 4px;
  display: grid;
  place-content: center;
  margin-right: 16px;
`;

const CloseIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: grid;
  place-content: center;
  cursor: pointer;
`;

const ModalBaseHeader: React.FC<ModalBaseProps> = (props) => {
  const { title, icon, onClose, header, variant = 'modern', subtitle } = props;

  let headerTitle: React.ReactNode = null;

  if (typeof title !== 'string') headerTitle = title;
  else if (title)
    headerTitle = (
      <>
        <Text color="neutral100" variant="h6">
          {title}
        </Text>
        {subtitle && (
          <Text color="neutral60" variant="body1Semibold">
            {subtitle}
          </Text>
        )}
      </>
    );

  let removeStyles = !title && !icon;
  removeStyles = removeStyles || variant !== 'modern';

  return (
    <HeaderWrapper header={header} removeStyles={removeStyles}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <TitleWrapper>{headerTitle}</TitleWrapper>
      {onClose && (
        <CloseIconWrapper onClick={onClose}>
          <Close />
        </CloseIconWrapper>
      )}
    </HeaderWrapper>
  );
};

export default ModalBaseHeader;
