export const FACEBOOK_TERM_POLICIES_URL = 'https://www.facebook.com/policies?ref=pf';

export const FACEBOOK_PRIVACY_POLICIES_URL = 'https://www.facebook.com/privacy/policy';

export const SHOPIFY_TERM_POLICIES_URL = 'https://www.shopify.com/legal/terms';

export const SHOPIFY_PRIVACY_POLICIES_URL = 'https://www.shopify.com/legal/privacy';

export const KLAVIYO_TERMS_OF_SERVICE_URL = 'https://www.klaviyo.com/legal/terms-of-service';

export const KLAVIYO_PRIVACY_POLICY_URL = 'https://www.klaviyo.com/legal/privacy/privacy-notice';

export const ANGLER_SIGN_UP_URL = 'https://apps.shopify.com/angler-ai-app';

export const ANGLER_TERMS_OF_SERVICE_URL = 'https://www.getangler.ai/eula';

export const ANGLER_PRIVACY_POLICY_URL = 'https://www.getangler.ai/privacy';

export const KLAVIYO_API_KEYS_URL = 'https://www.klaviyo.com/settings/account/api-keys';

export const KLAVIYO_LEARN_MORE_URL =
  'http://help.proxima.ai/en/articles/9827429-klaviyo-integration';

export const AUDIENCES_METHODOLOGIES_GUIDE_URL =
  'http://help.proxima.ai/en/articles/6974091-campaign-setup-testing-methodologies';

export const AUDIENCES_BUDGETING_GUIDE_URL =
  'http://help.proxima.ai/en/articles/7130934-budgeting-guide';

export const AUDIENCES_VIDEO_GUIDE_URL =
  'http://help.proxima.ai/en/articles/6974091-campaign-setup-testing-methodologies';

export const AUDIENCES_GETTING_STARTED_URL =
  'http://help.proxima.ai/en/articles/6974076-quickstart-guide-onboarding-to-proxima';

const MAIL_SUBJECT = 'Question about my Proxima account';
export const PROXIMA_CONTACT_SALES_EMAIL = `mailto:sales@proxima.ai?subject=${MAIL_SUBJECT}`;

export const AUDIENCES_QUICK_START_GUIDE_URL =
  'http://help.proxima.ai/en/articles/6974076-quickstart-guide';

export const AUDIENCES_ZOOM_WITH_EXPERT_URL =
  'https://calendly.com/josh-proxima/proxima-intro-clone';

export const FACEBOOK_CUSTOM_AUDIENCE_TERMS_OF_SERVICE_URL =
  'https://business.facebook.com/ads/manage/customaudiences/tos/';

export const META_BENCHMARKS_HELP_GUIDE_URL =
  'http://help.proxima.ai/en/collections/8378330-meta-benchmarks';

export const TACTICAL_BENCHMARKS_HELP_URL =
  'http://help.proxima.ai/en/collections/8378320-tactical-benchmarks';

export const MANUAL_IMPORT_HELP_URL = 'http://help.proxima.ai/en/articles/9213290-manual-import';

export const PROXIMA_SHARE_FEEDBACK_URL = 'https://6orzn58ck1l.typeform.com/to/Hey1zjqJ';
