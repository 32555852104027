import styled from '@emotion/styled';
import { colors, Divider, LabelStatus, Text } from '@innovationdepartment/proxima-ui';
import { TopAdsCreativePreviewModalProps } from './types';

const DetailsContentWrapper = styled.div`
  background-color: ${colors.white};
  min-width: 472px;
  height: 100%;
  padding: 32px;
`;

const SubdetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  & > :first-child {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const TopAdsCreativePreviewAdDetails = (props: TopAdsCreativePreviewModalProps) => {
  const { ad } = props;

  const status = ad?.status === 'ACTIVE' ? 'Active' : 'Inactive';
  const statusType = ad?.status === 'ACTIVE' ? 'success' : 'error';

  return (
    <DetailsContentWrapper>
      <DetailsWrapper>
        <Text variant="h4" isTermina={false} color="neutral90">
          {ad?.brand?.name}
        </Text>
        <Text variant="body2Medium" color="neutral70">
          {ad?.category}
        </Text>
      </DetailsWrapper>
      <Divider margin={24} />
      <SubdetailsWrapper>
        <DetailsWrapper>
          <Text variant="body2Semibold" color="neutral70">
            Status
          </Text>
          <LabelStatus label={status} variant={statusType} />
        </DetailsWrapper>
        <DetailsWrapper>
          <Text variant="body2Semibold" color="neutral70">
            Launch Date
          </Text>
          <div>-</div>
        </DetailsWrapper>
        <DetailsWrapper>
          <Text variant="body2Semibold" color="neutral70">
            CTA Link
          </Text>
          <div>TBD</div>
        </DetailsWrapper>
      </SubdetailsWrapper>
    </DetailsContentWrapper>
  );
};

export default TopAdsCreativePreviewAdDetails;
