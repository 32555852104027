import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';

/** TODO(Jenky): Might bring to ui lib */
const LoadingBar = styled.div<{ loading: boolean }>`
  z-index: 1;
  top: -1;
  left: 0;
  position: absolute;
  border-top: 4px solid ${colors.green10};
  background-color: ${colors.green10};

  transition: width 0.1s linear, opacity 0.2s linear;

  animation: ${({ loading }) => (loading ? 'loading 10s linear' : 'loaded 1s linear')} forwards;

  @keyframes loaded {
    0% {
      opacity: 1;
      width: 100%;
    }

    100% {
      opacity: 0;
      width: 100%;
    }
  }

  @keyframes loading {
    0% {
      opacity: 1;
      width: 0%;
    }

    60% {
      width: 80%;
    }

    80% {
      width: 90%;
    }

    95% {
      width: 95%;
    }

    98% {
      width: 96%;
    }

    100% {
      width: 99%;
    }
  }
`;

export default LoadingBar;
