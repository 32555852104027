import { ANGLER_PRIVACY_POLICY_URL, ANGLER_TERMS_OF_SERVICE_URL } from 'constants/urls';
import { Button } from '@innovationdepartment/proxima-ui';
import { AnglerIntegrationModalProps } from 'types/components/integrations';
import SideContentContainer from '../IntegrationsModal/LayoutComponents/SideContentContainer';
import SideContentTermsAndPrivacy from '../IntegrationsModal/LayoutComponents/SideContentTermsAndPrivacy';
import SideContentCategories from '../IntegrationsModal/LayoutComponents/SideContentCategories';
import styled from '@emotion/styled';

const CATEGORIES = ['Ecommerce', 'CAPI', 'Data & Enrichment'];

const LINKS = {
  terms: ANGLER_TERMS_OF_SERVICE_URL,
  privacy: ANGLER_PRIVACY_POLICY_URL,
};

const ConnectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const AnglerIntegrationModalSideContent = (props: {
  onConnectAnglerClick: AnglerIntegrationModalProps['onConnectAnglerClick'];
}) => {
  const { onConnectAnglerClick } = props;

  return (
    <SideContentContainer>
      <ConnectWrapper>
        <Button size="small" fullWidth label="Get started" onClick={onConnectAnglerClick} />
      </ConnectWrapper>
      <SideContentCategories categories={CATEGORIES} />
      <SideContentTermsAndPrivacy {...LINKS} />
    </SideContentContainer>
  );
};

export default AnglerIntegrationModalSideContent;
