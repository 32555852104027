import styled from '@emotion/styled';
import {
  colors,
  Text,
  MagicStars,
  ToggleButtons,
  LineChart,
} from '@innovationdepartment/proxima-ui';
import { AnchorOptions } from 'types/components/insights';
import { InsightsMetric } from 'types/insights';
import { traditionalBenchmarksCopyOptions } from '../chartCopy';
import { CenteredText, NoDataContainer } from './Insights.NoChartData';

const TraditionalBenchmarksContainer = styled.div`
  display: flex;
  height: 734px;
  gap: 32px;
  flex-direction: column;
  padding: 48px;
  align-items: center;
`;

const TraditionalBenchmarksHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChartContainer = styled.div`
  background-color: ${colors.neutral80};
  border: 2px solid ${colors.neutral70};
  border-radius: 12px;
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 24px;
  height: 450px;
  width: 1228px;
`;

const TraditionalBenchmarksChartTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 16px;
  padding: 16px 0;
  width: 376px;
`;

const MagicStarsWhite = styled(MagicStars)`
  fill: ${colors.white};
  width: 32px;
  height: 32px;
`;

const Title = styled.div`
  display: flex;
  gap: 12px;
`;

const TitleIcon = styled.div`
  width: 32px;
  height: 32px;
`;

type InsightsTraditionalBenchmarksProps = {
  currentMetric: InsightsMetric;
  metricsOptions: AnchorOptions[];
  chartOptions: any;
  onMetricChanged: (metric: InsightsMetric) => void;
};

const InsightsTraditionalBenchmarks = (props: InsightsTraditionalBenchmarksProps) => {
  const { currentMetric, metricsOptions, chartOptions, onMetricChanged } = props;
  const { brandInsights, flavorCatInsights } = chartOptions;

  const metricCopy = traditionalBenchmarksCopyOptions.find(
    (option) => option.metric === currentMetric
  )!;

  const noDataForTimeRange = brandInsights.length === 0 && flavorCatInsights.length === 0;

  return (
    <TraditionalBenchmarksContainer>
      <TraditionalBenchmarksHeader>
        <Text color="neutral70" variant="h4" isTermina>
          Traditional Benchmarks
        </Text>
      </TraditionalBenchmarksHeader>
      <ToggleButtons
        defaultValue={currentMetric}
        options={metricsOptions}
        onChange={(newMetric) => onMetricChanged(newMetric as InsightsMetric)}
        hasNewTheme
      />
      <ChartContainer>
        <TraditionalBenchmarksChartTextContainer>
          <Title>
            <TitleIcon>
              <MagicStarsWhite />
            </TitleIcon>
            <Text color="white" variant="h4" isTermina={false}>
              {metricCopy.title}
            </Text>
          </Title>
          {metricCopy.copy}
        </TraditionalBenchmarksChartTextContainer>
        {noDataForTimeRange ? (
          <NoDataContainer>
            <CenteredText>No data available for the selected time range</CenteredText>
          </NoDataContainer>
        ) : (
          <LineChart
            brandInsights={brandInsights}
            flavorCatInsights={flavorCatInsights}
            selectedMetric={currentMetric}
          />
        )}
      </ChartContainer>
    </TraditionalBenchmarksContainer>
  );
};

export default InsightsTraditionalBenchmarks;
