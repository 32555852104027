import styled from '@emotion/styled';
import {
  colors,
  LineChart,
  Text,
  ToggleButtons,
  Breakdown,
  Tooltip,
} from '@innovationdepartment/proxima-ui';
import { AnchorOptions } from 'types/components/insights';
import { InsightsMetric } from 'types/insights';
import Tag from 'ui/Tag';
import { performanceDifferencesCopyOptions } from '../chartCopy';
import { NoDataContainer, CenteredText } from './Insights.NoChartData';

const PerformanceDifferencesContainer = styled.div`
  background-color: ${colors.white};
  display: flex;
  height: 734px;
  gap: 32px;
  flex-direction: column;
  padding: 48px;
  align-items: center;

  > .toggle-bar-theme {
    height: 36px;
  }
`;

const PerformanceDifferencesHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChartContainer = styled.div`
  background: ${colors.neutral0};
  border-radius: 12px;
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 24px;
  height: 450px;
  width: 1228px;
`;

const PerformanceDifferencesChartTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 16px;
  padding: 16px 0;
  width: 376px;
`;

const IconLabelContainer = styled.div`
  border-radius: 4px;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;

  > svg {
    width: 16px;
  }

  > svg > path {
    fill: ${colors.neutral90};
  }
`;

const TooltipText = () => (
  <div style={{ padding: '12px 7px', width: 'center' }}>
    <Text variant="body3">
      This benchmark compares your business to peers with High, Median, and Low performance in your
      chosen Anchor metric.
    </Text>
  </div>
);

type InsightsPerformanceDifferencesProps = {
  currentMetric: InsightsMetric;
  anchorMetric: string;
  metricsOptions: AnchorOptions[];
  chartOptions: any;
  onMetricChanged: (metric: InsightsMetric) => void;
};

const InsightsPerformanceDifferences = (props: InsightsPerformanceDifferencesProps) => {
  const { currentMetric, anchorMetric, metricsOptions, chartOptions, onMetricChanged } = props;
  const { brandInsights, flavorCatInsights } = chartOptions;

  const metricCopy = performanceDifferencesCopyOptions.find(
    (option) => option.metric === currentMetric
  )!;
  const metricOptionsWithoutIcon = metricsOptions.map((option) => ({
    label: option.label,
    value: option.value,
  }));

  const noDataForTimeRange = brandInsights.length === 0 && flavorCatInsights.length === 0;

  return (
    <PerformanceDifferencesContainer>
      <PerformanceDifferencesHeader>
        <Text color="neutral70" variant="h4" isTermina>
          Performance Differences
        </Text>
      </PerformanceDifferencesHeader>
      <ToggleButtons
        defaultValue={currentMetric}
        options={metricOptionsWithoutIcon}
        onChange={(newMetric) => onMetricChanged(newMetric as InsightsMetric)}
        hasNewTheme
        hasToggleBar
      />
      <ChartContainer>
        <PerformanceDifferencesChartTextContainer>
          <Text color="neutral90" variant="h4" isTermina={false}>
            {metricCopy.title}
          </Text>
          <IconLabelContainer>
            <Tag
              label={
                <IconLabelContainer>
                  <Breakdown />
                  <Text variant="tag2Semibold" color="neutral80">
                    Anchor metric: {anchorMetric}
                  </Text>
                </IconLabelContainer>
              }
            />
            <Tooltip title={<TooltipText />} placement="bottom" arrow info />
          </IconLabelContainer>
          {metricCopy.copy}
        </PerformanceDifferencesChartTextContainer>
        {noDataForTimeRange ? (
          <NoDataContainer>
            <CenteredText>No data available for the selected time range</CenteredText>
          </NoDataContainer>
        ) : (
          <LineChart
            brandInsights={brandInsights}
            flavorCatInsights={flavorCatInsights}
            selectedMetric={currentMetric}
          />
        )}
      </ChartContainer>
    </PerformanceDifferencesContainer>
  );
};

export default InsightsPerformanceDifferences;
